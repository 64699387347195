<script setup lang="ts">
</script>
<style></style>
<template>
    <footer class="relative h-auto">
        <div class="w-full my-32 flex items-center justify-center">
            <ContactUs />
        </div>
        <FooterLinks />
        <FooterTerms />
    </footer>
</template>